<template>
  <ServicesDescription @connectService="connectService">
    <template #title>
      <h2>Голосовая рассылка</h2>
    </template>
    <template #body>
      <div>
        <h4>Голосовая рассылка - это автоматический обзвон большого числа абонентов.</h4>
      </div>
      <text-list :text="listText" :checkMark="false" :square="true"></text-list>
      <br />
      <h5>
        Простой и понятный интерфейс позволяет запустить голосовую рассылку за несколько минут!
      </h5>
      <ol class="list_numeric">
        <li v-for="item in instruction" :key="item">
          {{ item }}
        </li>
      </ol>
      <br />
      <h5>Голосовая рассылка ОнлайнЛоджик – это:</h5>
      <ul class="list_mark">
        <li v-for="item in descText" :key="item">
          {{ item }}
        </li>
      </ul>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  data() {
    return {
      listText: [
        'Проинформируйте клиентов об акциях, скидках, изменениях условий договора',
        'Напомните клиенту о важном',
        'Пригласите на мероприятие',
        'Проведите опрос',
        'Проконтролируйте качество обслуживания в вашей компании и соберите обратную связь'
      ],
      instruction: [
        'Загрузите список для рассылки ',
        'Создайте голосовое сообщение для проигрывания',
        'Настройте параметры рассылки',
        'Мы обзвоним все номера и предоставим подробный отчет!'
      ],
      descText: [
        'Удобный и функциональный Личный кабинет для рассылок',
        'Высокая скорость обзвона',
        'Выгодные тарифы',
        'Интеллектуальная система распознавания речи'
      ]
    };
  },

  components: {
    ServicesDescription,
    TextList: () => import('@/components/shared/TextList.vue')
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'голосовая-рассылка' }
      });
    }
  }
};
</script>

<style></style>
